<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mshiptypes-modals :modelName="modelName"></mshiptypes-modals>
	</div>
</template>

<script>
export default {
	name: 'mshiptypes-grid',
	components: {},
	data() {
		return {
			modelName: 'mshiptypes',

			modelColumnDefs: {}
		};
	}
};
</script>
